html,
body {
  height: 100%;
  font-weight: 400;
  font-family: 'Roboto', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', '"Helvetica Neue"',
    'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"';
  height: -webkit-fill-available;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}

div[role='presentation'] > .MuiPaper-root {
  border: 1px solid #e1e7f3 !important;
  box-shadow: none !important;
}
