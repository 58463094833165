.icon::before {
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #adb4c2;
  content: '';
  border-radius: 50%;
}

.checkedIcon::before {
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #3698e5;
  content: '';
  border-radius: 50%;
}

.checkedIcon::after {
  position: absolute;
  top: 3.5px;
  left: 3.5px;
  display: block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  background-color: #3698e5;
  content: '';
  border-radius: 50%;
}
